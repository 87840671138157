<template>
    <div class="pt-12 lg:pt-24">
        <div class="container">
            <global-breadcrumb :breadcrumb="breadcrumb" is-light class="flex justify-center mb-4 lg:mb-11" />
            <h2
                class="max-w-[720px] mx-auto text-center text-2xl sm:text-35px lg:text-5xl font-semibold !leading-[1.25] tracking-[-0.96px] text-blacks-100 mb-3 lg:mb-6"
            >
                {{ dataDetail?.title }}
            </h2>
            <div
                class="max-w-[720px] mx-auto text-center text-base lg:text-xl leading-[150%] text-blacks-70 mb-5 lg:mb-10"
            >
                {{ dataDetail?.intro }}
            </div>
            <div class="text-blacks-70 text-base text-center mb-8 lg:mb-16">
                {{ formatDate(dataDetail?.date_created) }}
            </div>
            <div class="max-w-[960px] mx-auto mb-12 lg:mb-24">
                <div class="aspect-16/8">
                    <NuxtImg
                        :src="getThumbnail(dataDetail?.thumbnail, 1500, true)"
                        width="1500"
                        sizes="sm:100vw md:50vw lg:1500px "
                        loading="lazy"
                        :alt="dataDetail?.title"
                        :placeholder="[50, 25, 75, 5]"
                        class="w-full h-full object-cover"
                    />
                    <!-- <img src="/images/home/banner.png" class="w-full h-full object-cover" /> -->
                </div>
            </div>
            <div class="max-w-[720px] mx-auto pb-12 lg:pb-24">
                <div class="post-content" v-html="dataDetail?.content"></div>
                <div
                    v-if="dataDetail?.for_agency"
                    @click="isOpen = true"
                    class="btn-arrow btn-effect-dark w-full flex items-center justify-center !text-white bg-blacks-100 px-12 lg:mt-24 h-15 cursor-pointer my-15 lg:my-24"
                >
                    <p>Đăng ký tham gia</p>
                </div>
                <div
                    class="pt-6 flex flex-col sm:flex-row gap-4 sm:gap-0 justify-between sm:items-start border-t border-solid border-[#EAEAEA] mt-6"
                >
                    <div class="flex gap-2 flex-wrap">
                        <nuxt-link
                            :to="`/tim-kiem?hashtag=${tag}`"
                            v-for="tag in listTags"
                            class="py-0.5 px-2.5 rounded-[16px] bg-#F3F3F3 text-blacks-90 text-sm leading-[20px] font-medium"
                        >
                            #{{ tag }}</nuxt-link
                        >
                    </div>
                    <div class="flex gap-3 items-center">
                        <div
                            @click="handleCopyLink"
                            class="relative px-4 py-2.5 flex gap-2 bg-white border border-solid border-[#EAEAEA] shadow-04 cursor-pointer whitespace-nowrap"
                        >
                            <icons-copy />
                            <div class="text-sm leading-[20px] text-blacks-90 font-semibold">Copy link</div>
                            <div
                                class="absolute -bottom-15 left-1/2 transform -translate-1/2 p-2 text-sm bg-black text-white whitespace-nowrap duration-300"
                                :class="copyLink ? 'opacity-100' : 'opacity-0'"
                            >
                                Copied to clipboard
                            </div>
                        </div>
                        <a
                            :href="shareLinkTwitter"
                            target="_blank"
                            class="w-10 h-10 flex justify-center items-center bg-white border border-solid border-[#EAEAEA] shadow-04"
                        >
                            <i class="i-mdi:twitter w-5 h-5 text-blacks-30"></i>
                        </a>
                        <a
                            :href="shareLinkFacebook"
                            target="_blank"
                            class="w-10 h-10 flex justify-center items-center bg-white border border-solid border-[#EAEAEA] shadow-04"
                        >
                            <i class="i-mdi:facebook w-5 h-5 text-blacks-30"></i>
                        </a>
                        <a
                            :href="shareLinkLinkedin"
                            target="_blank"
                            class="w-10 h-10 flex justify-center items-center bg-white border border-solid border-[#EAEAEA] shadow-04"
                        >
                            <i class="i-mdi:linkedin w-5 h-5 text-blacks-30"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div v-if="dataDetail?.related_news && dataDetail?.related_news.length > 0" class="pb-12 pt-0 lg:py-24">
                <div class="flex flex-col lg:flex-row gap-6 lg:gap-0 justify-between items-start mb-8 lg:mb-16">
                    <div class="">
                        <h3
                            class="text-blacks-100 text-2xl lg:text-4xl leading-[122.222%] tracking-[-0.72px] font-semibold mb-2 lg:mb-5"
                        >
                            Tin tức khác
                        </h3>
                        <div class="text-blacks-70 text-base lg:text-xl leading-[1.5]">
                            <v-text option_key="news_detail_related_intro" />
                        </div>
                    </div>
                    <nuxt-link
                        to="/tin-tuc"
                        class="hidden lg:flex btn-effect-dark w-max items-center gap-3 text-white bg-blacks-100 py-4 px-9 cursor-pointer"
                    >
                        <p>Xem tất cả</p>
                    </nuxt-link>
                </div>
                <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    <div v-for="(item, index) in dataDetail?.related_news" class="group">
                        <div class="h-[240px] w-full mb-6 overflow-hidden">
                            <NuxtImg
                                :src="getThumbnail(item?.thumbnail, 500, true)"
                                width="240"
                                sizes="sm:100vw md:50vw lg:240px "
                                loading="lazy"
                                :alt="item?.title"
                                :placeholder="[50, 25, 75, 5]"
                                class="w-full h-full object-cover transform duration-250 group-hover:scale-115"
                            />
                        </div>
                        <div class="text-blacks-70 text-sm font-semibold">{{ item?.category?.title }}</div>
                        <nuxt-link :to="item?.url" class="flex gap-4 my-2">
                            <div
                                class="max-w-[380px] text-blacks-100 text-base lg:text-2xl font-semibold hover:underline duration-250 line-clamp-2"
                            >
                                {{ item?.title }}
                            </div>
                            <span class="i-ic:baseline-arrow-outward w-6 h-6"></span>
                        </nuxt-link>
                        <div
                            class="text-sm lg:text-base text-blacks-90 mb-3 lg:mb-6 line-clamp-3 sm:min-h-[60px] lg:min-h-[72px]"
                        >
                            {{ item?.intro }}
                        </div>
                        <div class="text-blacks-90 text-sm">{{ formatDate(item?.date_created) }}</div>
                    </div>
                </div>
                <nuxt-link
                    to="/tin-tuc"
                    class="flex lg:hidden btn-effect-dark w-max items-center gap-3 text-white bg-blacks-100 py-4 px-9 cursor-pointer mt-8 mx-auto"
                >
                    <p>Xem tất cả</p>
                </nuxt-link>
            </div>
        </div>
    </div>
    <Teleport v-if="isOpen && dataDetail?.for_agency" to="body">
        <GlobalPopupSignUpAgency v-model:visible="isOpen" />
    </Teleport>
</template>

<script setup lang="ts">
defineProps({
    isAgency: {
        type: Boolean as any,
        default: false
    }
})
const isOpen = ref(false)
const route = useRoute()
const currentSlug = ref(route?.params?.slug)

const shareLinkFacebook = ref()
const shareLinkTwitter = ref()
const shareLinkLinkedin = ref()
const href = ref()
const copyToClipboard = (url: any) => {
    var sampleTextarea = document.createElement('textarea')
    document.body.appendChild(sampleTextarea)
    sampleTextarea.value = url //save main text in it
    sampleTextarea.select() //select textarea contenrs
    document.execCommand('copy')
    document.body.removeChild(sampleTextarea)
}
const copyLink = ref(false)
const handleCopyLink = () => {
    copyLink.value = true
    copyToClipboard(href.value)
    setTimeout(() => {
        copyLink.value = false
    }, 5000)
}

onMounted(() => {
    href.value = window.location.href
    shareLinkFacebook.value = `https://www.facebook.com/sharer/sharer.php?u=${href.value} `
    shareLinkTwitter.value = `https://twitter.com/share?text=&url=${href.value}`
    shareLinkLinkedin.value = `https://www.linkedin.com/sharing/share-offsite/?url=${href.value}`
})

const { getNewsDetailBySlug } = useNews()
const resDataDetail = await getNewsDetailBySlug({ slug: currentSlug.value })
const dataDetail = ref(resDataDetail?.model)
const listTags = ref(dataDetail.value?.tags?.split(','))

const breadcrumb = ref([
    {
        url: '/',
        title: 'Trang chủ'
    },
    {
        url: '/tin-tuc',
        title: 'Tin tức'
    },
    {
        url: dataDetail.value?.url,
        title: dataDetail.value?.title
    }
])

useDirectusCollectionSeo('news', {
    ...dataDetail.value,
    seo_detail: {
        ...dataDetail.value?.seo_detail
    }
})
</script>

<style></style>
